<script>
import {mapGetters} from "vuex";
import GameMixin from "@/mixins/GameMixin";
import {debounce} from "@/utils/debounce";

export default {
  name: "PlayerTurnActions",
  mixins: [GameMixin],
  components: {
    GamePlayerBettingHelper: () => import(/* webpackChunkName: "game-player-betting-helper-v2" */ '@/components/Game/PlayerBettingHelper.vue'),
  },
  data() {
    return {
      test: true,
      isBetConfirm: false,
      intervalTimer: null,
      betAmount: 0,
      bettingHelper: false,
      isFirstTurn: false,
      lastReservedActionTime: 0,
      callBet: 0,
      lastRoomState: -1,
    }
  },
  computed: {
    ...mapGetters('game', ['myInfo', 'roomInfo', 'tWaitTurn', 'playersInfo', 'isPlaying', 'isPaused', 'isShowdown', 'isShowResult', 'currentRoomState', 'isTournamentGame']),
    currentRoomState() {
      return this.roomInfo.state || -1;
    },
    currentCallBet() {
      return this.roomInfo.callBet || 0;
    },
    lastRaiseCash() {
      return this.roomInfo.lastRaiseCash || 0;
    },
    hasReservedAction() {
      return this.isCallReserved || this.isFoldReserved;
    },
    turn() {
      return this.myInfo.isMyTurn;
    },
    cash() {
      return this.myInfo.cash || 0;
    },
    betCash() {
      return this.myInfo.betCash || 0;
    },
    maxRaiseAmount() {
      // TODO : 기존 공식 cash + betCash 확인필요
      return this.cash;
      // return this.cash + this.betCash;
    },
    isPlayerInGame() {
      return this.myInfo.playState === this.playState.PLAYING;
    },
    minRaiseAmount() {
      return Math.min(this.maxRaiseAmount, this.roomInfo.minRaise);
    },
    canContinue() {
      return !!this.playersInfo.filter(
        p => p.playState === this.playState.PLAYING &&
          p.cash > 0 &&
          p.idx !== this.myInfo.idx
      ).length;
    },
    canPlayerAction() {
      if (!this.isPlaying || !this.isPlayerInGame) {
        return false;
      }

      return !(this.isShowdown || this.isShowResult);
    },
    isRaiseAvailable() {
      if (!this.canContinue) {
        return false;
      }

      if (this.betCash !== 0 && this.betCash === this.lastRaiseCash && this.betCash + this.cash >= this.roomInfo.minRaise) {
        return false;
      }

      return true;
    },
    // TODO : 기존 체크/콜 결합 버튼
    isCheckAction() {
      const {callBet} = this.roomInfo;
      return this.betCash === callBet;
    },
    callCash() {
      const {callBet} = this.roomInfo;
      return callBet - this.betCash > this.cash ? this.cash : callBet - this.betCash;
    },
    checkOrCall() {
      if (this.isCheckAction) {
        return '체크';
      }

      return this.callCash === this.cash ? '올인' : '콜';
    },
    checkOrFold() {
      const callBet = this.roomInfo.callBet || 0;

      if (this.betCash === callBet || this.isFoldReserved) {
        return '폴드/체크';
      }

      return '폴드';
    },
    raiseOrConfirm() {
      return this.isBetConfirm ? '확인' : '레이즈';
    },
    canReservation() {
      return this.cash > 0 && this.isPlayerInGame && !this.isShowdown;
    },
    canChat() {
      return this.myInfo && this.myInfo.idx !== -1;
    },
    isVisibleActions() {
      if(
          this.currentRoomState === this.roomState.BreakTime ||
          this.currentRoomState === this.roomState.WaitOtherPlayer
      ){
        return false;
      }

      return this.isPlaying || this.isPaused;
    },
  },
  watch: {
    turn(value, _value) {
      if (value === false && _value === true) {
        this.bettingHelper = false;
      }
    },
    bettingHelper() {
      if (!this.bettingHelper) {
        this.betAmount = 0;
        this.isBetConfirm = false;
      }
    }
  },
  methods: {
    reservedActionDelay() {
      if (!this.isFirstTurn) {
        return 500;
      }

      return this.currentRoomState === this.roomState.FlopAction ? 2500 : 1800;
    },
    updatePlayerTurnRemains() {
      if (!this.isPaused) {
        const now = Date.now();

        let remainSec = Math.floor((Number(this.tWaitTurn) - now) / 1000);
        let remainSecMs = Math.floor((Number(this.tWaitTurn) - now));
        remainSec = remainSec < 0 ? 0 : remainSec;

        this.$store.commit('game/SET_TURN_REMAINS', remainSec);
        this.$store.commit('game/SET_TURN_REMAINS_MS', remainSecMs);
      }
    },
    update() {
      this.updatePlayerTurnRemains();

      if (this.lastRoomState !== this.currentRoomState) {
        this.__reserveCall(false);
        this.lastReservedActionTime = 0;
        this.lastRoomState = this.currentRoomState;
      }

      this.isFirstTurn = this.roomInfo.turnIdx === this.myInfo.idx;

      if (!this.hasReservedAction) {
        return;
      }

      if (this.currentRoomState === this.roomState.ShowResult) {
        this.__reserveCall(false);
        this.__reserveFold(false);
        return;
      }

      if (!this.turn) {
        return;
      }

      // 예약액션 딜레이
      if (this.hasReservedAction && this.callBet === this.currentCallBet) {
        const now = Date.now();

        if (this.lastReservedActionTime === 0) {
          this.lastReservedActionTime = now;
          return;
        }

        if (now - this.lastReservedActionTime < this.reservedActionDelay()) {
          return;
        }
      }

      this.performReservedAction();
    },
    performReservedAction() {
      if (this.isFoldReserved) {
        this.lastReservedActionTime = 0;
        this.__reserveFold(false);
        if (this.currentCallBet === this.betCash) {
          this.callAction();
        } else {
          this.foldAction();
        }
      } else if (this.isCallReserved) {
        this.lastReservedActionTime = 0;
        this.__reserveCall(false);
        if (this.callBet === this.currentCallBet) {
          this.callAction()
        }
      }
    },
    onBetConfirm(amount) {
      const amountValue = Number(amount);
      const isValid = amountValue || 0;

      if (isValid === 0) {
        alert('잘못된 레이즈 입니다.');
        return;
      }

      this.betAmount = isValid ? amount : 0;
      this.isBetConfirm = isValid;
    },
    foldAction: debounce(function () {
      this.bettingHelper = false;

      if (!this.turn) {
        return;
      }

      const {sn: roomId} = this.roomInfo;
      const {idx: playerId} = this.myInfo;
      this.__reserveFold(false);
      this.fold(roomId, playerId);
    }, 500, {leading: true}),
    /**
     * TODO : 작업 완료 시 turn check 복원
     * 기존 : 체크/콜 결합 버튼
     */
    callAction: debounce(function () {
      this.bettingHelper = false;

      if (!this.turn) {
        return;
      }

      const {sn: roomId} = this.roomInfo;
      const {idx: playerId} = this.myInfo;
      this.__reserveCall(false);
      this.call(roomId, playerId);
    }, 500, {leading: true}),
    /**
     * 기존 : 레이즈/벳 결합 버튼
     */
    betAction: debounce(function () {
      if (!this.turn) {
        return;
      }

      // 베팅 메뉴 Open
      if (!this.bettingHelper) {
        this.bettingHelper = !this.bettingHelper;
        return;
      }

      // bettingHelper : Action
      this.bettingHelper = false;
      const {sn: roomId} = this.roomInfo;
      const {idx: playerId} = this.myInfo;
      this.bet(roomId, playerId, this.betAmount)
    }, 500, {leading: true}),
    reserveFold() {
      this.bettingHelper = false;
      this.__reserveFold(!this.isFoldReserved);
      this.__reserveCall(false);
    },
    reserveCall() {
      this.bettingHelper = false;
      this.__reserveCall(!this.isCallReserved);
      this.__reserveFold(false);

      this.callBet = this.isCallReserved ? this.currentCallBet : 0;
    }
  },
  mounted() {
    this.intervalTimer = setInterval(this.update, 100);
  },
  beforeDestroy() {
    this.intervalTimer && clearInterval(this.intervalTimer);
  }
}

</script>

<template>
  <div class="inner_footer_game">
    <!--정보버튼-->
    <div class="mb-3" v-if="isTournamentGame">
      <button
          type="button"
          class="btn_info"
          data-bs-toggle="offcanvas"
          data-bs-target="#layerpopup_info"
          aria-controls="offcanvasBottom"
      >
        <img src="@/assets/v2/img/btn_info.png" width="45px" alt="">
      </button>
    </div>
    <!-- 이모티콘,채팅,정보 버튼 -->
    <div class="menu_options">
      <div>
        <!--채팅버튼-->
        <div v-if="isVisibleActions && canChat">
          <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#layerpopup_emoji"
              aria-controls="offcanvasBottom"
          >
            <img src="@/assets/v2/img/btn_chat.png" width="45px" alt="">
          </button>
        </div>
      </div>
    </div>

    <!-- 베팅버튼 -->
    <transition appear name="fade-down" mode="out-in" v-if="isVisibleActions">
      <div v-if="turn && canPlayerAction && !hasReservedAction" class="menu_bet" :class="{wait: !turn}">
        <button
          type="button"
          class="bet_option fold"
          :class="{active: !turn}"
          @click.prevent.stop="foldAction"
        >
          폴드
        </button>
        <button
          type="button"
          class="bet_option"
          :class="{active: !turn, check: isCheckAction, call: !isCheckAction}"
          @click.prevent.stop="callAction"
        >
          {{ checkOrCall }}
          <span v-show="!isCheckAction" :class="{txt_num: !isCheckAction}">
            [ {{ callCash | formatCash }} ]
          </span>
        </button>
        <button
          v-if="isRaiseAvailable"
          type="button"
          class="bet_option bet"
          :class="{active: !turn}"
          @click.prevent.stop="betAction"
        >
          {{ raiseOrConfirm }}
        </button>
      </div>
      <!-- // 베팅버튼 -->

      <div v-else-if="canPlayerAction && canReservation" class="menu_bet" :class="{wait: !turn}">
        <button
          type="button"
          class="bet_option fold"
          :class="{active: !turn && isFoldReserved}"
          @click.prevent.stop="reserveFold"
        >
          <span :class="{check: !turn}">{{ checkOrFold }}</span>
        </button>
        <button
          type="button"
          class="bet_option call"
          :class="{active: !turn && isCallReserved}"
          @click.prevent.stop="reserveCall"
        >
          <span :class="{check: !turn}">콜</span>
        </button>
      </div>
    </transition>

    <!-- 베팅금액설정 레이어팝업 -->
    <GamePlayerBettingHelper
      v-if="bettingHelper"
      :isTournamentGame="isTournamentGame"
      :range="[minRaiseAmount, maxRaiseAmount]"
      @confirm="onBetConfirm"
      @close="bettingHelper = !bettingHelper"
    />
    <!-- // 베팅금액설정 레이어팝업 -->
  </div>
</template>

<style scoped>
.fade-down-enter-active {
  transition-delay: 0.5s;
}

.fade-down-leave-active {
  transition-delay: 0s;
}

.fade-down-enter-active, .fade-down-leave-active {
  transition: all .5s ease-in-out;
}

.fade-down-enter, .fade-down-appear, .fade-down-leave-to {
  position: absolute;
  opacity: 0;
  transform-origin: bottom center;
  transform: translateY(100%);
}

button.bet_option {
  transition: transform 50ms ease-in-out, background-color 50ms ease;
}

button.bet_option:active {
  transform-origin: center center;
  transform: scale(0.94);
  background-color: #2980b9;
}
</style>